import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import CpmtjListUtil ,{ICpmtjListDataObj} from './cpmtjListUtil';
export default defineComponent ({
    name: 'CountryList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ICpmtjListDataObj=reactive<ICpmtjListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.OrderProviderApi.buildUrl('/cpmtj/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CpmtjListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })



        return{
            ...toRefs(dataObj)
        }
    }
});